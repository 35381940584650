
export default {
  data() {
    return {
      expandSearch:false,
      openCart: false,
      openMenu: false,
      search: "",
      categoryOpen: false,
      cart: { items: [] },
    };
  },
  watch: {
     $route: function (v, o) {
      if (v.path != o.path) {
        this.openCart = false;
        this.openMenu = false;
        this.expandSearch = false;
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
    openCart: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
    openMenu: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
  },
  mounted() {
    this.cart = this.$hyperCart.getCart();
    this.$nuxt.$on("updateCart", () => {
      this.cart = this.$hyperCart.getCart();
    });
  },
  methods: {
    doSearch() {
      let query = [];
      if (this.search) {
        query.push(`q=${this.search}`);
      }

      this.$router.push({ path: `/search/+${query.join("+")}` });
    },
  },
};
